// Bootstrap
// ---------------------------------------------------
@import "~bootstrap/scss/functions";
@import "~admin-lte/build/scss/bootstrap-variables";
@import "~bootstrap/scss/mixins";

$btn-colors: (
  "primary": #5a8dee,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": #ff5b5c,
  "light": $light,
  "dark": $dark,
);
@import "~admin-lte/plugins/fontawesome-free/css/all.min.css";
@import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
@import "~admin-lte/dist/css/adminlte.min.css";

@each $color, $value in $btn-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    font-family: "IBM Plex Sans", sans-serif;
  }
}
body {
  font-family: "Rubik", sans-serif !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: "Rubik", sans-serif !important;
  font-weight: 400;
  line-height: 1.2;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.website-list-filter ul.other-date-options li [type="radio"]:checked,
ul.other-date-options li [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.website-list-filter ul.other-date-options li [type="radio"]:checked + label {
  background: #1877f2;
  padding: 6px 35px;
  border: 1px solid #1877f2;
  color: #fff;
}
ul.other-date-options li [type="radio"]:checked + label {
  background: #1877f2;
  padding: 6px 35px;
  border: 1px solid #1877f2;
  color: #fff;
}
.website-list-filter
  ul.other-date-options
  li
  [type="radio"]:not(:checked)
  + label {
  border: 1px solid #a1b3c1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 6px 35px;
}
div.radio label {
  cursor: pointer;
}
ul.other-date-options {
  gap: 5px;
}
.direction-column {
  flex-direction: column;
  row-gap: 12px;
}
.direction-row {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  min-height: 46px;
}
.direction-column ul.other-date-options li {
  width: 100%;
}
.direction-column div.radio label {
  width: 100%;
  text-align: center;
}
ul.other-date-options {
  flex-wrap: wrap;
}
.selectbox {
  padding: 10px;
  line-height: 16px;
  border-width: 0 1px 4px;
  border: 1px solid #a1b3c1;
  cursor: pointer;
}
.filterBlock {
  list-style: none;
  padding: 5px 10px;
}
.filterButton {
  height: 38px;
  border-radius: unset;
}
.dark-mode a:not(.btn) {
  color: #fff;
}

.light-mode a:not(.btn) {
  color: #343a40;
}

.bg-danger td > a {
  color: #fff;
}
.dark-mode pre {
  color: #fff;
}

.custom-switch .custom-control-label::before {
  cursor: pointer;
}

.apexcharts-svg.apexcharts-zoomable {
  cursor: crosshair;
  background: transparent !important;
}
tbody td > span.dividerV:not(:last-child):not(.badge):after {
  content: "";
  border-left: 1px solid #5a8dee;
  margin-left: 10px;
  padding-left: 10px;
}
.more-webistes {
  cursor: pointer;
}
.c-active {
  color: green;
}
.c-inactive {
  color: red;
}

.can-toggle {
  position: relative;
  display: flex;
}
.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(234, 0, 48, 0.5);
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #ea0030;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #b70026;
}
.can-toggle input[type="checkbox"]:hover ~ label {
  color: #d1002b;
}
.can-toggle input[type="checkbox"]:checked ~ label:hover {
  color: #55bc49;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #4fb743;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch,
.can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color: #5fc054;
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  color: #47a43d;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #ff0538;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #ea0030;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  transform: translate3d(35px, 0, 0);
}
.can-toggle
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle label {
  font-size: 14px;
  flex: 0 0 30%;
  margin: 5px 0;
  cursor: pointer;
}
.can-toggle label .can-toggle__switch {
  height: 20px;
  flex: 0 0 75px;
  border-radius: 4px;
}
.can-toggle label .can-toggle__switch:before {
  left: 35px;
  font-size: 12px;
  line-height: 20px;
  width: 35px;
  padding: 0 12px;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 35px;
  line-height: 16px;
  font-size: 12px;
}
.can-toggle label .can-toggle__switch:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked
  ~ label
  .can-toggle__switch:after {
  transform: translate3d(44px, 0, 0);
}
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked:focus
  ~ label
  .can-toggle__switch:after,
.can-toggle.can-toggle--size-small
  input[type="checkbox"]:checked:hover
  ~ label
  .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small label {
  font-size: 13px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch {
  height: 18px;
  flex: 0 0 90px;
  border-radius: 2px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
  left: 45px;
  font-size: 10px;
  line-height: 28px;
  width: 45px;
  padding: 0 12px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
  top: 1px;
  left: 1px;
  border-radius: 1px;
  width: 44px;
  line-height: 26px;
  font-size: 10px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.introjs-hint {
  z-index: 999999;
}

.zone-block {
  min-height: 110px;
}

.card-animate {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.card-animate:hover {
  -webkit-transform: translateY(calc(-1.5rem / 5));
  transform: translateY(calc(-1.5rem / 5));
}
.avatar-sm {
  height: 3rem;
  width: 3rem;
}
.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(10, 179, 156, 0.18);
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.sidebar .nav-link .badge {
  left: 5px;
  bottom: 8px;
}
.c-pointer {
  cursor: pointer;
}
.table th,
.table td {
  padding: 0.3rem;
}

.table-responsive thead {
  position: sticky;
  top: 0;
  background: #fff;
}

.dark-mode .table-responsive thead {
  background-color: #3f474e;
}

.sticky.dark-mode .table tbody > tr > td:first-of-type {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #3f474e;
}

.sticky .table tbody > tr > td:first-of-type {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fff;
}

.text-bonus {
  font-weight: 700;
  color: #33cc33;
}

.collapse-icon-background {
  width: 30px;
  height: 30px;
  background: #6c757da1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
