.dark-question-block {
    background: #343a40;
    position: absolute;
    right: 15px;
    bottom: 0;
    width: 400px;
    padding: 15px;
    color: white;
    box-shadow: 1px -3px 13px 0px #7c7c7b;
}

.question-block {
    position: absolute;
    right: 15px;
    bottom: 0;
    width: 400px;
    background: #f4f6f9;
    padding: 15px;
    color: black;
    box-shadow: 1px -3px 13px 0px #7c7c7b;
}

.close {
    cursor: pointer;
    font-size: 15px;
    color:red
}
.qusetion-list {
    height: 400px;
    overflow: auto;
    margin-top: 10px;
}
.qusetion-list ul {
    list-style: none;
    padding: 0 15px;
}
.qusetion-list ul li input {
    cursor: pointer;
}
.answer-label {
    display: inline;
    font-weight: 500 !important;
    padding: 0 15px;
    cursor: pointer;
}
.textarea {
    height: 90px;
    width: 100%;
    margin-top: 15px;
    border-radius: 5px;
}