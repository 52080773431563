.rmsc .dropdown-content {
  z-index: 100;
}
.rmsc label {
  margin-bottom: 0;
}

.rmsc .item-renderer input {
  margin: 0 10px 0 0;
}

.rmsc.dark-mode .dropdown-content .panel-content {
  background-color: #343a40;
}

.rmsc.dark-mode .select-item.selected {
  background-color: #515559;
}

.rmsc.dark-mode .select-item:hover {
  background-color: #515559;
}

.rmsc.dark-mode .dropdown-heading {
  background-color: #343a40;
}
.rmsc.dark-mode .dropdown-container {
  border: 1px solid #6c757d;
}

.rmsc.dark-mode .search input:focus {
  background: transparent;
  border: 1px solid #798da3;
  color: #fff;
}

.rmsc.dark-mode .search input {
  color: #fff;
}
.rmsc.dark-mode .search input::placeholder {
  color: #aaa;
}
