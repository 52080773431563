.dropdown-item {
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer; 
}
.notificationsDropdown {
    transform: translateX(-50%) !important;
    left: 50% !important;
    
}
.dropdown-list{
    max-height: 500px;
    overflow-y: auto;
}
.dropdown-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #0f0f0f;
  }

  .dropdown-list::-webkit-scrollbar {
    width: 5px;
    background-color: #0f0f0f;
  }

  .dropdown-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #837a7a;
  }